'use strict';

angular.module('enervexSalesappApp')
	.factory('Auth', function Auth($rootScope, $http, User, $cookieStore, $q, _) {
		var currentUser = {};

		var _auth = {
			forgotPassword: function(user, callback) {
				var cb = callback || angular.noop;
				var deferred = $q.defer();
				$http({
					url:'/api/passwords',
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					data: {
						email: user.email
					}
				}).then(function(result) {
					var data = result.data;
					deferred.resolve(data);
					return cb();
				}).catch(function(err) {
					deferred.reject(err);
					return cb(err);
				}.bind(this));
				return deferred.promise;
			},
			resetPassword:function(token, user, callback) {
				var cb = callback || angular.noop;
				var deferred = $q.defer();
				$http({
					url:'/api/passwords/'+token,
					method: "PUT",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					data: {
						password: user.password
					}
				}).then(function(result) {
					var data = result.data;
					currentUser = data.user;
					deferred.resolve(data);
					return cb();
				}).catch(function(err) {
					deferred.reject(err);
					return cb(err);
				}.bind(this));

				return deferred.promise;
			},
			sendPassword:function(user, callback) {
				var cb = callback || angular.noop;
				var deferred = $q.defer();
				$http({
					url:'/api/passwords/send-password/'+user._id,
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					data: {
					}
				}).then(function(result) {
					var data = result.data;
					deferred.resolve(data);
					return cb();
				}).catch(function(err) {
					deferred.reject(err);
					return cb(err);
				}.bind(this));

				return deferred.promise;
			},
			resendLogin:function(user, password, callback) {
				var cb = callback || angular.noop;
				var deferred = $q.defer();
				$http({
					url:'/api/users/'+user._id+'/resend-login',
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					data: {
						password: password
					}
				}).then(function(result) {
					var data = result.data;
					deferred.resolve(data);
					return cb();
				}).catch(function(err) {
					deferred.reject(err);
					return cb(err);
				}.bind(this));

				return deferred.promise;
			},
			_login:function(result){
				currentUser = result.user;
				console.log("_login set currentUser", currentUser)
			},
			login: function(user, callback) {
				var cb = callback || angular.noop;
				var deferred = $q.defer();
				$http({
					url:'/auth',
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					},
					data: user
				}).then(function(result) {
					var data = result.data;
					currentUser = data.user;
					console.log("login set currentUser", currentUser)
					deferred.resolve(data);
					return cb();
				}).catch(function(err) {
					deferred.reject(err);
					return cb(err);
				}.bind(this));

				return deferred.promise;
			},
			cancel: function(callback) {
				var cb = callback || angular.noop;
				var deferred = $q.defer();
				$http({
					method: "DELETE",
					url:'/auth/dialog/authorize',
					data:{},
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					}
				}).then(function(result) {
					var data = result.data;
					deferred.resolve(data);
					return cb(data);
				}).catch(function(e){
					deferred.resolve({});
					return cb({});
				});
				return deferred.promise;
			},
			logout: function(callback) {
				currentUser = {};
				console.log("logout set currentUser", currentUser)
				var cb = callback || angular.noop;
				var deferred = $q.defer();

				$http({
					method: "DELETE",
					url:'/auth',
					data:{},
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					}
				}).then(function(result) {
					var data = result.data;
					deferred.resolve(data);
					return cb(data);
				}).catch(function(e){
					deferred.resolve({});
					return cb({});
				});
				return deferred.promise;
			},
			createUser: function(user, callback) {
				var cb = callback || angular.noop;
				return User.save(user,
					function(data) {
						return cb(user);
					},
					function(err) {
						return cb(err);
					}.bind(this)).$promise;
			},
			changePassword: function(oldPassword, newPassword, callback) {
				var cb = callback || angular.noop;

				return User.changePassword({
					id: currentUser._id
				}, {
					oldPassword: oldPassword,
					newPassword: newPassword
				}, function(user) {
					return cb(user);
				}, function(err) {
					return cb(err);
				}).$promise;
			},
			getCurrentUser: function() {
				return currentUser;
			},
			isLoggedIn: function() {
				return currentUser.hasOwnProperty('role');
			},
			isLoggedInAsync: function(cb) {
				if (currentUser.hasOwnProperty('$promise')) {
					console.log("isLoggedInAsync() excuting promise on currentUser", currentUser)
					currentUser.$promise.then(function() {
						cb(true);
					}).catch(function() {
						cb(false);
					});
				} else if (currentUser.hasOwnProperty('role')) {
					cb(true);
				} else {
					_auth.getSession(false).then(function() {
						cb(true);
					}).catch(function(err){
						cb(false);
					})
				}
			},
			accountPermission:function(account){
				if (_auth.accessAllAccounts()){
					return {
						createJob: true,
					}
				} else {
					var member = _.find(account.members, function(member){
						return member.user._id == currentUser._id
					})
					if (member) {
						if (member.role == "owner"){
							return {
								createJob: true
							}
						} else {
							return member.permissions
						}
					} else {
						return {
							createJob:false
						}
					}
				} 
			},
			jobPermissions:function(job, config){
				if (_auth.accessAllAccounts()){
					return {
						owner: true,
						internal: true,
						pricing:  true,
						edit: true,
					}
				} else {
					var member = _.find(job.members, function(member){
						return member.user._id == currentUser._id
					})
					if (member){
						var permissions = _.clone(member.role.permissions)
						_.each(currentUser.permissions, function(value, name){
							permissions[name] = value
							return true
						})
						return permissions
					} else {
						return {
							owner:false,
							internal: false,
							pricing: false,
							edit: false,
						}
					}
				} 
			},
			// hasJobAccess:function(job, permission){
			// 	if (_auth.isAdmin()){
			// 		return true
			// 	}
			// 	var member = _.find(job.members, function(member){
			// 		return member.user._id == currentUser._id
			// 	})
			// 	return member && member.role.permissions[permission]
			// },
			isCommercial: function() {
				return currentUser.role && currentUser.role.permissions && currentUser.role.permissions.commercial;
			},
			isAccountOwner: function(account) {
				if (!account){
					return false;
				}
				if (_auth.accessAllAccounts()){
					return true;
				}
				var member = _.find(account.members, function(member){
					return member.user._id == currentUser._id;
				})
				return member && member.role == 'owner';
			},
			isInternalMember:function(job){
				if (_auth.accessAllAccounts()){
					return true
				}
				var pemissions = _auth.jobPermissions(job, {});
				return pemissions.internal
			},
			isAdmin: function() {
				return currentUser.role && currentUser.role.permissions && currentUser.role.permissions.admin;
			},
			accessAllAccounts: function() {
				return currentUser.role && currentUser.role.permissions && currentUser.role.permissions.accessAllAccounts;
			},
			isUserAdmin: function() {
				return currentUser.role && currentUser.role.permissions && currentUser.role.permissions.adminUsers;
			},
			hasUserPermission: function(name) {
				return this.isAdmin() || currentUser.permissions[name]
			},
			getSession:function(logout) {
				var deferred = $q.defer();
				$http({
					url:'/auth',
					method: "GET",
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/json"
					}
				}).then(function(result) {
					var data = result.data;
					currentUser = data.user;
					// console.log("getSession() set currentUser", currentUser)
					deferred.resolve(data);
				}).catch(function(err) {
					if (logout !== false) {
						_auth.logout();
					}
					deferred.reject(err);
				}.bind(this));
				return deferred.promise;
			}
		};
		return _auth;
	});
